<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    id="org_id"
                                    :options="orgList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-model="sub_sector.org_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Sector" vid="sector_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="sector_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('research_manage.sector')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="sub_sector.sector_id"
                                :options="sectorList"
                                id="sector_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Sub Sector Name(En)" vid="sub_sector_name" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="sub_sector_name"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.sub_sector_name')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="sub_sector_name"
                              v-model="sub_sector.sub_sector_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Sub Sector Name(Bn)" vid="sub_sector_name_bn" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="sub_sector_name_bn"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.sub_sector_name_bn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="sub_sector_name_bn"
                              v-model="sub_sector.sub_sector_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Sub Sector Description(En)" vid="sub_sector_description">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="sub_sector_description"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.sector_description_en')}}
                              </template>
                              <b-form-textarea
                              id="sub_sector_description"
                              v-model="sub_sector.sub_sector_description"
                              ></b-form-textarea>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Sub Sector Description(Bn)" vid="sub_sector_description_bn">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="sub_sector_description_bn"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.sub_sector_description_bn')}}
                              </template>
                              <b-form-textarea
                              id="sub_sector_description_bn"
                              v-model="sub_sector.sub_sector_description_bn"
                              ></b-form-textarea>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../../config/api_config'
import { subSectorInfoStore, subSectorInfoUpdate } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id'],
  mixins: [researchTestingModal],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        sub_sector: {
            org_id: 0,
            sector_id: '',
            sub_sector_code: '',
            sub_sector_name: '',
            sub_sector_name_bn: '',
            sub_sector_description: '',
            sub_sector_description_bn: ''
        },
        sectorList: []
    }
  },
  computed: {
    orgList: function () {
      const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
      return objectData.map(div => {
            return { value: div.value, text: this.$i18n.locale === 'en' ? div.text_en : div.text_bn }
          })
    }
    // sectorList: function () {
    //     return this.$store.state.AgriResearch.commonObj.sectorList
    // }
  },
  watch: {
      'sub_sector.org_id': function (newValue, oldValue) {
          if (newValue !== oldValue) {
              this.sectorList = this.getSectorList(newValue)
          } else {
              this.sectorList = []
          }
      }
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.sub_sector.org_id = this.$store.state.Auth.authUser.org_id
      // if (this.$store.state.Auth.authUser.is_org_admin !== 1) {
      //   this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
      // }
    }
    if (this.id) {
        const tmp = this.getSubSectorData()
        // Object.freeze(tmp)
        this.sub_sector = tmp
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    getSectorList (orgId) {
        if (this.sub_sector.org_id) {
            const area = this.$store.state.AgriResearch.commonObj.sectorList.filter(item => item.status === 1)
            if (orgId) {
            return area.filter(item => item.org_id === parseInt(orgId))
            }
            return area
        }
    },
    getSubSectorData () {
      const tmpData = this.$store.state.list.find(project => project.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${subSectorInfoUpdate}/${this.id}`, this.sub_sector)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, subSectorInfoStore, this.sub_sector)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
